<template>
    <a-layout class="TrainingStaffAdmin">
        <a-layout>
            <a-layout-header class="app-header">
                <div class="logo">
                    <a href="/"><img src="../assets/images/ic_logo_white.png" alt=""></a>
                </div>
                <a-menu
                        theme="dark"
                        mode="horizontal"
                        :defaultSelectedKeys="[this.$route.path]"
                        :style="{ lineHeight: '64px' }"
                >
                    <a-menu-item key="/dashboard">
                        <router-link :to="{ path: '/' }" class="nav-link">
                            <a-icon type="dashboard" />
                            <span>Dashboard</span>
                        </router-link>
                    </a-menu-item>
                    <a-sub-menu key="/products">
                        <span slot="title"><a-icon type="book" /><span>Sản phẩm</span></span>
                        <a-menu-item key="/products">
                            <router-link :to="{ path: '/products' }" class="nav-link">
                                <a-icon type="ordered-list" />
                                <span>Tất cả sản phẩm</span>
                            </router-link>
                        </a-menu-item>
                        <a-menu-item key="/products/categories">
                            <router-link :to="{ path: '/products/categories' }" class="nav-link">
                                <a-icon type="copy" />
                                <span>Danh mục sản phẩm</span>
                            </router-link>
                        </a-menu-item>
                        <a-menu-item key="/products/tags">
                            <router-link :to="{ path: '/products/tags' }" class="nav-link">
                                <a-icon type="tags" />
                                <span>Danh sách thẻ</span>
                            </router-link>
                        </a-menu-item>
                    </a-sub-menu>
                    <a-menu-item key="/groups">
                        <router-link :to="{ path: '/groups' }" class="nav-link">
                            <a-icon type="appstore" />
                            <span>Nhóm sản phẩm</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item key="/gallery">
                        <router-link :to="{ path: '/gallery' }" class="nav-link">
                            <a-icon type="picture" />
                            <span>Thư viện</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item key="/orders">
                        <router-link :to="{ path: '/orders' }" class="nav-link">
                            <a-icon type="shopping" />
                            <span>Đơn hàng</span>
                        </router-link>
                    </a-menu-item>
                    <a-sub-menu key="/orders">
                        <span slot="title"><a-icon type="setting" /><span>Cài đặt</span></span>
                        <a-menu-item key="/">
                            <router-link :to="{ path: '/banners' }" class="nav-link">
                                <a-icon type="plus-circle" />
                                <span>Banners</span>
                            </router-link>
                        </a-menu-item>
                    </a-sub-menu>
                </a-menu>
                <div class="header-right">
                    <a-dropdown>
                        <a-menu slot="overlay">
                            <a-menu-item @click="logout" key="3"><a-icon type="user" />Đăng xuất</a-menu-item>
                        </a-menu>
                        <a-button style="margin-left: 8px">Xin chào <strong style="margin: 0 10px">{{ getUser.fullName }}</strong> <a-icon type="down" />
                        </a-button>
                    </a-dropdown>
                </div>
            </a-layout-header>
            <router-view></router-view>
        </a-layout>
    </a-layout>
</template>

<script>

    import { mapGetters } from 'vuex';

    export default {
        name: "TrainingStaffAdmin",
        data() {
            return {
                collapsed: false
            };
        },
        computed: {
            ...mapGetters('authenticate', [
                'getUser'
            ])
        },
        methods: {
            logout: async () => {
                // eslint-disable-next-line no-console,no-undef
                await ssoLogout()
            }
        }
    };
</script>

<style lang="scss">
    .TrainingStaffAdmin {
        background-color: #31343d;

        .trigger {
            font-size: 18px;
            line-height: 64px;
            padding: 0 24px;
            cursor: pointer;
            transition: color .3s;
        }

        .app-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 25px;
        }

        .ant-menu.ant-menu-dark .ant-menu-item.ant-menu-item-selected,
        .ant-menu.ant-menu-dark .ant-menu-item.ant-menu-item-actived {
            background-color: transparent;
        }

        .header-right{
            text-align: right;
            width: 100%;
        }

        .logo img {
            height: 58px;
            margin-bottom: 10px;
            margin-right: 30px;
        }
    }
</style>
